import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const BootstrapWrap = (props) => {
	return (
		<React.Fragment>
			<Container fluid>
				<Row>
					<Col >
						{props.children}
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	)
}

export default BootstrapWrap;