import React from 'react';
import './Header.css';
import logo from '../../assets/svg/one.svg';

const Header = (props) => {
	const title = props.title.map(titleText => {
		let stripedString = titleText.title.rendered.replace("#038;", "");
		stripedString = stripedString.replace('[logo-big]', '<span class="logo-big">');
		stripedString = stripedString.replace('[logo-small]', '<span class="logo-small">');
		stripedString = stripedString.replace(new RegExp('\\\[[^\\[]*end[^\\]]*\\]', 'g'), '</span>');

		return (
			<div key={titleText.id} className="header">
				<div className="header_text">
					<h1 dangerouslySetInnerHTML={{ __html: stripedString }}></h1>
					<div dangerouslySetInnerHTML={{ __html: titleText.acf.title }}
						className="title"></div>
					<div dangerouslySetInnerHTML={{ __html: titleText.acf.subtitle }}
						className="subtitle"></div>
				</div>
				<div className={props.stickyNav ? 'containerFixed' : ''}>
					<div className={props.stickyNav ? 'containerFluid' : ''}>
						<div className={props.stickyNav ? 'stickyImgWrap imgWrap' : 'imgWrap'}>
							<img src={logo} alt="logo" className={props.isOpen && !props.stickyNav ? "header_image_opacity" : "header_image"} />
						</div>

					</div>
				</div>

			</div>
		)
	})
	return (
		<header>
			{title}
		</header>
	)
}

export default Header;