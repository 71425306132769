import React, { Component } from 'react';
import {
	Collapse, Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink,
	NavbarBrand
} from 'reactstrap';
import './NavBar.css';

import { Link } from 'react-scroll';
import Scrollspy from 'react-scrollspy';

class NavBar extends Component {

	render() {
		const navItems = this.props.navMenu.map(item => {
			return item.title
		});

		const [about, lawyers, support, contact] = navItems;

		return (
			<Scrollspy items={['section-1', 'section-2', 'section-3', 'section-4']}>
				<Navbar light expand='md'>
					<NavbarToggler onClick={this.props.toggle} />
					<NavbarBrand id='showOnCollapse'>MENU</NavbarBrand>
					<Collapse isOpen={this.props.isOpen} navbar className={this.props.sticky ? 'dropDown' : 'dropUp'}>
						<Nav className="ml-xl-5 pl-5" navbar >
							<NavItem>
								<Link to='about-section' smooth={true} duration={500}>
									<NavLink >{about}</NavLink>
								</Link>
							</NavItem>
							<NavItem>
								<Link to='lawyers-section' smooth={true} duration={500} offset={-50}>
									<NavLink >{lawyers}</NavLink>
								</Link>
							</NavItem>
							<NavItem>
								<Link to='support-section' smooth={true} duration={500} offset={-250}>
									<NavLink >{support}</NavLink>
								</Link>
							</NavItem>
							<NavItem>
								<Link to='contact-section' smooth={true} duration={500} >
									<NavLink >{contact}</NavLink>
								</Link>
							</NavItem>
							<NavItem>
								<NavLink onClick={this.props.languageSwitcher} className='hoverable'>
									<span className={this.props.lang === 'en' ? 'white' : ''}>EN</span>|
                                    <span className={this.props.lang === 'de' ? 'white' : ''}>DE</span>
								</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar >
			</Scrollspy >
		)
	}
}

export default NavBar;