import React, { Component } from "react";
import "./App.css";
import BackToTop from "./assets/svg/top.svg";

import axios from "axios";
import { Helmet } from "react-helmet";

import BootstrapWrap from "./components/BootstrapWrap/BootstrapWrap";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Header from "./components/Header/Header";
import NavBar from "./components/NavBar/NavBar";
import About from "./components/About/About";
import AboutBackground from "./components/About/AboutBackground/AboutBackground";
import Lawyers from "./components/Lawyers/Lawyers";
import Support from "./components/Support/Support";
import FormPage from "./components/Form/Form";
import Contact from "./components/Contact/Contact";
import Team from "./components/Team/Team";
import { Link, Element } from "react-scroll";

var _ = require("lodash");

class App extends Component {
  state = {
    data: {
      title: [],
      about: [],
      lawyers: [],
      team: [],
      support: [],
      contact: [],
      navMenu: [],
      seo: [],
      headings: {},
      mapConfig: {},
    },
    loading: true,
    defaultLang: "de",
    currentLang: "",
    stickyNav: false,
    showBackToTop: false,
    isOpen: false
  };

  componentDidMount() {
    // Make event listener on screen scroll so i can control navbar position.
    window.addEventListener("scroll", this.checkSticky.bind(this));
    window.addEventListener(
      "orientationchange",
      this.orientationChange.bind(this)
    );
    this.resize(); // Get initial vh
    this.checkSticky();
    let lastLanguge = localStorage.getItem(`currentLanguage`);
    if (!lastLanguge) {
      lastLanguge = this.state.defaultLang;
    }
    this.setState(
      {
        currentLang: lastLanguge
      },
      () => {
        this.getAPIdata();
      }
    );
  }

  componentWillUnmount() {
    // Remove listeners, throttle the force update.
    window.removeEventListener("scroll", this.checkSticky.bind(this));
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    var vh = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  getAPIdata = () => {
    const currentLang = this.state.currentLang;
    this.setState(
      {
        currentLang: currentLang
      },
      () => {
        localStorage.setItem(`currentLanguage`, currentLang);
      }
    );

    // If this language exist in local storage
    this.setState({ loading: true });
    if (localStorage.getItem(`data_${currentLang}`)) {
      const newState = JSON.parse(
        localStorage.getItem(`data_${this.state.currentLang}`)
      );
      this.setState({ data: newState, loading: false });
    }

    // Get all data from API
    axios
      .all([
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/wp/v2/title`
        ),
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/wp/v2/about`
        ),
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/wp/v2/lawyers`
        ),
        axios.get(
          `${
            process.env.REACT_APP_URL
          }${currentLang}/wp-json/wp/v2/support_team`
        ),
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/wp/v2/contact`
        ),
        axios.get(
          `${
            process.env.REACT_APP_URL
          }${currentLang}/wp-json/wp-api-menus/v2/menus/25`
        ),
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/wp/v2/seo/458`
        ),
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/wp/v2/team`
        ),
        axios.get(
          `${process.env.REACT_APP_URL}${currentLang}/wp-json/google-maps-settings/v1/api`
        )
      ])
      .then(
        axios.spread(
          (title, about, lawyers, support, contact, navMenu, seo, team, mapConfig) => {
            this.setState({
              data: {
                title: title.data,
                about: about.data,
                lawyers: lawyers.data,
                team: team.data,
                support: support.data,
                contact: contact.data,
                navMenu: navMenu.data.items,
                seo: seo.data,
                headings: {
                  lawyers: navMenu.data.items[1].title,
                  support: navMenu.data.items[2].title,
                  contact: navMenu.data.items[3].title
                },
                mapConfig: {
                    api_key: mapConfig.data.gearsaid_maps_api_key,
                    lat: parseFloat(mapConfig.data.gearsaid_maps_latitude),
                    lng: parseFloat(mapConfig.data.gearsaid_maps_longitude),
                    zoom: parseInt(mapConfig.data.gearsaid_maps_zoom)
                }
              },
              loading: false
            });
            // If local storage is not set for current language
            if (localStorage.getItem(`data_${currentLang}`) === null) {
              localStorage.setItem(
                `data_${currentLang}`,
                JSON.stringify(this.state.data)
              );
              localStorage.setItem(`currentLanguage`, currentLang);
            }

            // If API data is changed set it as local storage
            if (
              !_.isEqual(
                this.state.data,
                JSON.parse(localStorage.getItem(`data_${currentLang}`))
              )
            ) {
              localStorage.setItem(
                `data_${currentLang}`,
                JSON.stringify(this.state.data)
              );
              // If API didnt change just finish loading
            }
            if (
              _.isEqual(
                this.state.data,
                JSON.parse(localStorage.getItem(`data_${currentLang}`))
              )
            ) {
              this.setState({ loading: false });
            }
          }
        )
      );
  };

  checkSticky() {
    this.setState({
      // Close navigation when scrolling
      isOpen: false
    });
    if (!this.state.loading) {
      const headerHeight = document.querySelector(".HeaderWrap").offsetHeight;

      if (window.scrollY >= headerHeight) {
        // Check to show or hide back to top icon
        this.setState({ showBackToTop: true });
      } else {
        this.setState({ showBackToTop: false });
      }

      if (window.scrollY >= headerHeight) {
        this.setState({
          stickyNav: true // set stickyNav to true so i can controll classes on navbar
        });
      } else {
        this.setState({
          stickyNav: false
        });
      }
    }
  }

  orientationChange() {
    setTimeout(this.resize, 200);
  }

  toggle = event => {
    // Bootstrap default toggle function for show/hide nav items.
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  languageSwitcher = () => {
    if (this.state.currentLang === "en") {
      this.setState(
        {
          currentLang: "de"
        },
        () => {
          this.getAPIdata();
        }
      );
    } else if (this.state.currentLang === "de") {
      this.setState(
        {
          currentLang: "en"
        },
        () => {
          this.getAPIdata();
        }
      );
    }
  };

  render() {
    return !this.state.loading ? ( // Render everything when page has finished loading
      <React.Fragment>
        <Helmet>
          <title>RCS Trust & Legal</title>
          <meta
            name="description"
            content="The law firm RCS Trust & Legal located in Zurich.
            RCS Trust & Legal is providing tax,
            legal and regulatory advice to private clients,
            wealthy families and companies."
          />
          <meta
            name="keywords"
            content="lawyer in Switzerland, lawyer in Zurich, attorney, RCS Trust & Legal, lawyer advice"
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
            integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
            crossorigin=""
          />
        </Helmet>
        <header
          className="IndexWrap"
          ref={section => {
            this.Start = section;
          }}
        >
          <div className="HeaderWrap" onClick={this.notify}>
            <div className="HeaderWrapBackgroundPlaceholder"></div>
            <Element name='hero-section'></Element>
              <BootstrapWrap>
                <Header
                  title={this.state.data.title}
                  language={this.state.lang}
                  stickyNav={this.state.stickyNav}
                  isOpen={this.state.isOpen}
                  iOSSafari={this.state.iOSSafari}
                />
              </BootstrapWrap>
            
          </div>

          <div className={this.state.stickyNav ? "NavWrap sticky" : "NavWrap"}>
            <BootstrapWrap>
              {this.state.data.navMenu.length !== 0 && (
                <NavBar
                  lang={this.state.currentLang}
                  isOpen={this.state.isOpen}
                  toggle={this.toggle}
                  sticky={this.state.stickyNav}
                  languageSwitcher={this.languageSwitcher}
                  navMenu={this.state.data.navMenu}
                />
              )}
            </BootstrapWrap>
          </div>
        </header>

        <section
          id="section-1"
          ref={section => {
            this.About = section;
          }}
        >
          <div
            className={
              this.state.stickyNav ? "AboutWrap topMargin" : "AboutWrap"
            }
          >
            <BootstrapWrap>
              <About about={this.state.data.about} />
            </BootstrapWrap>
            <AboutBackground about={this.state.data.about} />
          </div>
        </section>

        <section
          id="section-2"
          ref={section => {
            this.Lawyers = section;
          }}
        >
          <div className="LawyersWrap">
            <div className="Lawyers_background" />
            <BootstrapWrap>
              <Lawyers
                lawyers={this.state.data.lawyers}
                heading={this.state.data.headings.lawyers}
              />
            </BootstrapWrap>
          </div>
        </section>

        <section
          id="section-3"
          ref={section => {
            this.Support = section;
          }}
        >
          <div className="TeamWrap">
            <div className="Team_background" />
            <BootstrapWrap>
              <Team
                team={this.state.data.team}
                lang={this.state.currentLang}
                heading={this.state.data.headings.support}
              />
            </BootstrapWrap>
          </div>
        </section>

        <section id="section-4">
          <div className="SupportWrap">
            <div className="Support_background" />
            <BootstrapWrap>
              <Support support={this.state.data.support} />
            </BootstrapWrap>
          </div>
        </section>

        <section
          id="section-5"
          ref={section => {
            this.Contact = section;
          }}
        >
          <div className="FormWrap">
            <h2>{this.state.data.headings.contact}</h2>
            <BootstrapWrap>
              <FormPage lang={this.state.currentLang} />
            </BootstrapWrap>
          </div>
        </section>

        <footer className="ContactWrap">
          <div className="Contact_background" />
          <BootstrapWrap>
            <Contact
              contact={this.state.data.contact}
              lang={this.state.currentLang}
              mapConfig={this.state.data.mapConfig}
            />
          </BootstrapWrap>
        </footer>

          <script
            src="https://unpkg.com/leaflet@1.6.0/dist/leaflet.js"
            integrity="sha512-gZwIG9x3wUXg2hdXF6+rVkLF/0Vi9U8D2Ntg4Ga5I5BZpVkVxlJWbSQtXPSiUTtC0TjtGOmxa1AJPuV0CPthew=="
            crossorigin=""
          ></script>
        <div
          className={
            this.state.showBackToTop ? "showBackToTop" : "hideBackToTop"
          }
        >
          <Link to='hero-section' smooth={true} duration={500} >
            <img src={BackToTop} alt="BacktoTopIcon" className="topIcon" /> 
          
          </Link>
        </div>
      </React.Fragment>
    ) : (
      <LoadingScreen /> // Render Loading Screen animation when page is still loading
    );
  }
}

export default App;
