import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = () => {
	return (
		<div className='loadingScreen'>
			<div className="spinner">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	)
}

export default LoadingScreen;