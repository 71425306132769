import React, { Component } from "react";
import "./Team.css";
import CV from "../../assets/svg/cv.png";
import { Container, Row, Col } from "reactstrap";

export class Team extends Component {
  render() {
    const Team = this.props.team.map((member) => {
      const memberCV = member.acf.resume.url;
      return (
        <Container key={member.id}>
          <Row className="member">
            <Col xs="8" className="member_text">
              <h3>{member.title.rendered}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: member.acf.information }}
              ></div>
            </Col>
            <Col xs="auto" className="member_img">
              <a href={member.acf.resume.url} target="_blank">
                <img
                  src={member.acf.picture.sizes.medium}
                  alt="Team member"
                  className="imageFadeOut"
                />
              </a>
              {/* Do not show CV image if its not included */}
              {memberCV !== undefined && (
                <a href={member.acf.resume.url} target="_blank">
                  <img src={CV} alt="Resume" className="cv" />
                </a>
              )}
            </Col>
          </Row>
        </Container>
      );
    });

    return (
      <section>
        <h2 className="member_heading">{this.props.heading}</h2>
        {Team}
      </section>
    );
  }
}

export default Team;
