import React, { Component } from "react";
import "./Form.css";
import Send from "../../assets/svg/send.png";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import { isEmail } from "validator";
import { ToastContainer, toast, Slide } from "react-toastify";

const required = (value, props) => {
  if (!value || (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">Required</span>;
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <span className="form-error is-visible">
        {value} is not a valid email.
      </span>
    );
  }
};

export default class FormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      confirmationMessage: "",
    };
    this.submitHandler = this.submitHandler.bind(this);
  }

  nameHandler = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  emailHandler = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  messageHandler = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    fetch(
      `${process.env.REACT_APP_URL}${this.props.lang}/wp-json/contact-form-7/v1/contact-forms/f05b652/feedback`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((post) => {
        this.setState({
          name: "",
          email: "",
          message: "",
          confirmationMessage: post.message,
        });
      })
      .then(() => {
        this.notify(); // Display Confirmation Message after geting API feedback
        this.removeApiError(); // Reset form input class names and req after email is sent
      });
  };

  notify = () => {
    const confirmationMessage = this.state.confirmationMessage;
    toast(`${confirmationMessage}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  removeApiError = () => {
    this.form.hideError(this.userName);
    this.form.hideError(this.userEmail);
    this.form.hideError(this.userMessage);
  };

  render() {
    let message =
      this.props.lang === "en"
        ? "Write us direct message and we will get in touch with you shortly"
        : "Schreiben Sie uns eine direkte Nachricht und wir werden uns in Kürze mit Ihnen in Verbindung setzen";
    let name =
      this.props.lang === "en"
        ? " Enter your name"
        : "Geben Sie Ihren Namen ein";
    let emailAddress =
      this.props.lang === "en"
        ? " Enter your email"
        : "Geben Sie Ihre E-Mail Adresse ein";
    let note =
      this.props.lang === "en"
        ? "Enter your message"
        : "Geben Sie Ihre Nachricht ein";

    return (
      <React.Fragment>
        <div className="form_group">
          <Form
            onSubmit={this.submitHandler}
            className="form"
            ref={(c) => {
              this.form = c;
            }}
          >
            <p className="mobile_paragh">{message}</p>
            <div className="formGroup">
              <label>
                {name}
                <Input
                  value={this.state.name}
                  onChange={this.nameHandler}
                  name="yname"
                  type="name"
                  validations={[required]}
                  id="formInput"
                  ref={(c) => {
                    this.userName = c;
                  }}
                />
              </label>
            </div>

            <div className="formGroup">
              <label>
                {emailAddress}
                <Input
                  value={this.state.email}
                  onChange={this.emailHandler}
                  name="yemail"
                  type="email"
                  validations={[required, email]}
                  id="formInput"
                  ref={(c) => {
                    this.userEmail = c;
                  }}
                />
              </label>
            </div>

            <div className="formGroup">
              <label>
                {note}
                <Textarea
                  value={this.state.message}
                  onChange={this.messageHandler}
                  type="textarea"
                  name="ymessage"
                  validations={[required]}
                  id="formInput"
                  ref={(c) => {
                    this.userMessage = c;
                  }}
                />
              </label>
            </div>

            <div className="right">
              <label>{message}</label>
              <Button id="Popover1">
                <img src={Send} alt="send button" />
              </Button>
            </div>
          </Form>
        </div>
        <ToastContainer transition={Slide} draggablePercent={60} />
      </React.Fragment>
    );
  }
}
