import React from "react";
import "./About.css";
import { Element } from 'react-scroll';
import { Row, Col } from "reactstrap";

const About = props => {
  const about = props.about.map(info => {
    return (
      <React.Fragment key={info.id}>
        <Element name='about-section'>
          <Row id="about">
            <Col xs="6" />
            <Col xs="6" className="about_text">
              <h2>{info.title.rendered}</h2>
              <div dangerouslySetInnerHTML={{ __html: info.acf.informations }} className="about_content"></div>
            </Col>
          </Row>
          <Row id="medium">
            <Col className="about_text">
              <h2>{info.title.rendered}</h2>
              <div dangerouslySetInnerHTML={{ __html: info.acf.informations }} className="about_content"></div>
            </Col>
          </Row>
        </Element>
      </React.Fragment>
    );
  });

  return <section>{about}</section>;
};

export default About;
