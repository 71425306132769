import React from "react";
import "./Contact.css";
import GoogleMap from "./GoogleMap/GoogleMap";
import { Element } from 'react-scroll';
import TermsOfUse from "./TermsOfUse/TermsOfUse";

const Contact = props => {
  const ContactData = props.contact.map(data => {
    const stripedString = data.title.rendered.replace("#038;", "");
    return (
      <div className="contact_text" key={data.id}>
        <h2>{stripedString}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.acf.info }} />
        <TermsOfUse
          className="terms_of_use_modal"
          terms={data.acf.terms_of_use}
          linkLabel={data.acf.hyperlink_label}
        />
      </div>
    );
  });
  return (
    <section>
      <Element name='contact-section'>
        <div className="map">
          <GoogleMap
              contact={props.contact}
              mapConfig={props.mapConfig}
          />
        </div>
        {ContactData}
      </Element>
    </section>
  );
};

export default Contact;
