import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./TermsOfUse.css";

class TermsOfUse extends React.Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  render() {
    return (
      <div className="terms">
        <a onClick={this.toggle} className="modal_button">
          {this.props.linkLabel}
        </a>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle} />
          <ModalBody dangerouslySetInnerHTML={{ __html: this.props.terms }} />
          <ModalFooter> </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default TermsOfUse;
