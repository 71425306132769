import React from "react";
import "./Lawyers.css";
import CV from "../../assets/svg/cv.png";
import { Element } from 'react-scroll';
import { Container, Row, Col } from "reactstrap";

const Lawyers = (props) => {
  const Lawyers = props.lawyers.map((lawyer) => {
    const lawyerCV = lawyer.acf.resume.url;
    return (
      <Container key={lawyer.id}>
        <Row className="lawyer">
          <Col xs="8" className="lawyer_text">
            <h3>{lawyer.title.rendered}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: lawyer.acf.information }}
            ></div>
          </Col>
          <Col xs="auto" className="lawyer_img">
            <a href={lawyer.acf.resume.url} target="_blank">
              <img
                src={lawyer.acf.picture.sizes.medium}
                alt="Lawyer"
                className="imageFadeOut"
              />
            </a>
            {/* Do not show CV image if its not included */}
            {lawyerCV !== undefined && (
              <a href={lawyer.acf.resume.url} target="_blank">
                <img src={CV} alt="Resume" className="cv" />
              </a>
            )}
          </Col>
        </Row>
      </Container>
    );
  });

  return (
    <section>
      <Element name='lawyers-section'>
        <h2 className="lawyers_heading">{props.heading}</h2>
        {Lawyers}
      </Element>
    </section>
  );
};

export default Lawyers;
