import React from "react";
import "./Support.css";
import { Col } from "reactstrap";
import { Element } from 'react-scroll';
import CV from "../../assets/svg/cv.png";

const Support = (props) => {
  const support_team = props.support.map((member, index) => {
    const memberCV = member.acf.resume.url;
    const willBeSinglePerRow = props.support.length % 3 === 1;
    const thirdFromBehind = index === props.support.length - 3;

    {
      /* Make sure that there is never only one in row */
    }
    if (willBeSinglePerRow && thirdFromBehind) {
      return (
        <Col
          xs="4"
          className="support_member"
          key={member.id}
          style={{ marginRight: "1%" }}
        >
          <h3>{member.title.rendered}</h3>
          <img
            src={member.acf.picture.sizes.medium}
            alt="member"
            className="imageFadeOut"
          />
          {/* Do not show CV image if its not included */}
          {memberCV !== undefined && (
            <a href={member.acf.resume.url} target="_blank">
              <img src={CV} alt="Resume" className="cv" />
            </a>
          )}
        </Col>
      );
    } else {
      return (
        <Col xs="4" className="support_member" key={member.id}>
          <h3>{member.title.rendered}</h3>
          <img
            src={member.acf.picture.sizes.medium}
            alt="member"
            className="imageFadeOut"
          />
          {/* Do not show CV image if its not included */}
          {memberCV !== undefined && (
            <a href={member.acf.resume.url} target="_blank">
              <img src={CV} alt="Resume" className="cv" />
            </a>
          )}
        </Col>
      );
    }
  });
     return <Element name='support-section'>
        <section className="support">
          {support_team}
        </section>
      </Element>;
};

export default Support;
