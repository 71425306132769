import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';
import pin from '../../../assets/svg/pin.png';
import './GoogleMap.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'

const MarkerCustom = () => <img src='/assets/svg/pin.png' alt="example" className='marker' />

class GoogleMap extends Component {
	
	render() {
		const ApiKey  = this.props.mapConfig.api_key;
		const lat  = this.props.mapConfig.lat;
		const lng  = this.props.mapConfig.lng;
		console.log('coordinates ', lat, ' ', lng)
		const zoom = this.props.mapConfig.zoom;
		const center = [lat, lng];

		const googleMapAddress = `https://maps.google.com/maps?ll=${lat},${lng}&z=${zoom}&t=m&hl=en-US&gl=US&mapclient=apiv3`;
		const Link = () => <a href={googleMapAddress} className='customAdressLink'>Navigate in Google maps</a>;
		return (
			// Important! Always set the container height explicitly
			<div className='MapWrap'>
				<Link />
				<MapContainer center={center} zoom={zoom} scrollWheelZoom={false}>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					<Marker position={center}>
					</Marker>
				</MapContainer>
			</div>
		);
	}
}

export default GoogleMap;