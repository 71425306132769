import React from "react";
import "./AboutBackground.css";
import { Col, Row, Container } from "reactstrap";

const AboutBackground = (props) => {
  const AboutBackground = props.about.map((info) => {
    return (
      <React.Fragment key={info.id}>
        <div className="About_background">
          <div className="About_background_img" />
          <Container fluid>
            <Row id="about_wide">
              <Col xs="6" className="about_img">
                <img
                  src={info.acf.image.sizes.medium_large}
                  alt="people"
                  className="aboutImg"
                />
              </Col>
              <Col xs="6">
                <div
                  dangerouslySetInnerHTML={{ __html: info.acf.informations2 }}
                  className="about_text_second"
                ></div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="about_small">
          <Row>
            <Col>
              <div
                dangerouslySetInnerHTML={{ __html: info.acf.informations2 }}
                className="about_text_second"
              ></div>
            </Col>
          </Row>
          <Row>
            <Col className="about_img">
              <div className="About_background">
                <div className="About_background_img" />
              </div>
              <img
                src={info.acf.image.sizes.medium_large}
                alt="people"
                className="aboutImg"
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  });
  return <React.Fragment>{AboutBackground}</React.Fragment>;
};

export default AboutBackground;
